<template>
    <default-layout>
        <el-form class="contract-search__form row my-3 mx-1"
                 :model="searchForm"
                 :rules="searchRules"
                 ref="contractSearchForm"
                 @submit.prevent="onSearch">
            <el-form-item class="col-12 d-flex flex-column" prop="filter">
                <div class="fw-bold font-small-3">Search on:</div>
                <el-select class="w-100" v-model="searchForm.filter" placeholder="Select">
                    <el-option
                        v-for="option in CONTRACT_SEARCH_FIELD_OPTIONS"
                        :key="option.key"
                        :label="option.label"
                        :value="option.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="col-12 d-flex justify-content-center"
                          prop="value">
                <el-input
                    v-model="searchForm.value"
                    placeholder="Search"
                    prefix-icon="el-icon-search"
                    clearable
                />
            </el-form-item>
            <div class="col-12 d-flex justify-content-center">
                <el-button native-type="submit" type="info" :loading="isSearching">Search</el-button>
            </div>
        </el-form>
        <div class="text-center fw-bold mt-3"
             v-if="!isSearching && searchCounter > 0 && searchedReservations.length === 0">
            No contracts were found.
        </div>
        <div class="row my-3 px-2 px-sm-0 mx-sm-1">
            <div class="reservation mb-2 col-12" v-for="reservation in searchedReservations" :key="reservation.id">
                <div class="my-2 d-flex justify-content-between reservation-header">
                    <div class="fw-bold text-primary" v-if="reservation.client.type === 'B'">
                        {{ `${reservation.client.company_name} ${reservation.client.company_type}` }}
                    </div>
                    <div class="fw-bold text-primary" v-else>
                        {{ capitalizeAll(`${reservation.client.first_name} ${reservation.client.last_name}`) }}
                    </div>
                    <div class="fw-bold">{{ `${reservation.source_id} (${reservation.id})` }}</div>
                </div>
                <div class="reservation-body d-flex flex-wrap">
                    <contract-button class="contract-btn"
                                     v-for="contract in reservation.contracts"
                                     :key="contract.id"
                                     :contract="contract"
                                     @click="getContractInfo(contract.id)"/>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import {contractContext} from "@/store";
import {ContractSearchFieldFilterValue} from "@/types/strings";
import _ from "lodash";
import ContractButton from "@/components/controls/ContractButton.vue";
import ReservationData from "@/http/data/reservation-data";
import ContractData from "@/http/data/contract-data";
import {ElForm} from "element-plus";

@Options({
    name: "ContractSearch",
    components: {
        ContractButton,
        DefaultLayout,
    }
})
export default class ContractSearch extends Vue {
    readonly CONTRACT_SEARCH_FIELD_OPTIONS = [
        {
            key: "contract_id",
            label: "Contract ID",
            value: "contract_id",
        },
        {
            key: "reservation_id",
            label: "Reservation ID",
            value: "reservation_id",
        },
        {
            key: "person_name",
            label: "Person Name",
            value: "person_name",
        },
        {
            key: "phone_number",
            label: "Phone number",
            value: "phone_number",
        },
        {
            key: "email",
            label: "Email",
            value: "email",
        }
    ];

    searchForm = {
        value: "",
        filter: this.contractSearchFieldFilter
    };

    searchRules = {
        value: [
            {
                required: true,
                message: "Input is required.",
                trigger: "blur"
            },
            {
                min: 3,
                message: "Input must contain at least 3 characters.",
                trigger: "blur"
            }
        ]
    };

    public searchCounter = 0;

    public isSearching = false;

    get contractSearchFieldFilter(): ContractSearchFieldFilterValue {
        return contractContext.state.contractSearchFieldFilter;
    }
    set contractSearchFieldFilter(value: ContractSearchFieldFilterValue) {
        contractContext.actions.selectContractSearchFieldFilter(value);
    }
    
    get searchedReservations(): ReservationData[] {
        return _.orderBy(contractContext.state.searchedReservations, reservation => {
            return _.chain(reservation.contracts)
                .map((contract: ContractData) => contract.start_date)
                .min()
                .value();
        }, ["desc"]);
    }

    async onSearch(): Promise<void> {
        const contractSearchForm = this.$refs["contractSearchForm"] as typeof ElForm;

        await contractSearchForm.validate(async (isValid: boolean) => {
            if (!isValid) return;

            await this.search();
        });
    }

    async search(): Promise<void> {
        this.isSearching = true;

        try {
            await contractContext.actions.searchReservation(this.searchForm);
        } finally {
            this.searchCounter++;
            this.isSearching = false;
        }
    }

    async getContractInfo(contractId: string): Promise<void> {
        await contractContext.actions.selectContractInfoId(contractId);

        await this.$router.push({name: "Contract"});
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.contract-btn {
    &:not(:last-child) {
        border-bottom: 1px dotted black;
    }
}

.contract-search__form {
    &:deep {
        .el-form-item__content {
            line-height: inherit;
        }
    }
}

.reservation-body, .reservation-header {
    column-gap: 1rem;
}
</style>