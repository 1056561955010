
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import {contractContext} from "@/store";
import {ContractSearchFieldFilterValue} from "@/types/strings";
import _ from "lodash";
import ContractButton from "@/components/controls/ContractButton.vue";
import ReservationData from "@/http/data/reservation-data";
import ContractData from "@/http/data/contract-data";
import {ElForm} from "element-plus";

@Options({
    name: "ContractSearch",
    components: {
        ContractButton,
        DefaultLayout,
    }
})
export default class ContractSearch extends Vue {
    readonly CONTRACT_SEARCH_FIELD_OPTIONS = [
        {
            key: "contract_id",
            label: "Contract ID",
            value: "contract_id",
        },
        {
            key: "reservation_id",
            label: "Reservation ID",
            value: "reservation_id",
        },
        {
            key: "person_name",
            label: "Person Name",
            value: "person_name",
        },
        {
            key: "phone_number",
            label: "Phone number",
            value: "phone_number",
        },
        {
            key: "email",
            label: "Email",
            value: "email",
        }
    ];

    searchForm = {
        value: "",
        filter: this.contractSearchFieldFilter
    };

    searchRules = {
        value: [
            {
                required: true,
                message: "Input is required.",
                trigger: "blur"
            },
            {
                min: 3,
                message: "Input must contain at least 3 characters.",
                trigger: "blur"
            }
        ]
    };

    public searchCounter = 0;

    public isSearching = false;

    get contractSearchFieldFilter(): ContractSearchFieldFilterValue {
        return contractContext.state.contractSearchFieldFilter;
    }
    set contractSearchFieldFilter(value: ContractSearchFieldFilterValue) {
        contractContext.actions.selectContractSearchFieldFilter(value);
    }
    
    get searchedReservations(): ReservationData[] {
        return _.orderBy(contractContext.state.searchedReservations, reservation => {
            return _.chain(reservation.contracts)
                .map((contract: ContractData) => contract.start_date)
                .min()
                .value();
        }, ["desc"]);
    }

    async onSearch(): Promise<void> {
        const contractSearchForm = this.$refs["contractSearchForm"] as typeof ElForm;

        await contractSearchForm.validate(async (isValid: boolean) => {
            if (!isValid) return;

            await this.search();
        });
    }

    async search(): Promise<void> {
        this.isSearching = true;

        try {
            await contractContext.actions.searchReservation(this.searchForm);
        } finally {
            this.searchCounter++;
            this.isSearching = false;
        }
    }

    async getContractInfo(contractId: string): Promise<void> {
        await contractContext.actions.selectContractInfoId(contractId);

        await this.$router.push({name: "Contract"});
    }
}
